import terms from 'assets/terms'
import { Button } from 'components'
import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import { routes } from 'services'

export default function AuthPage() {
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    if (!auth.isLoading || !auth.activeNavigator) {
      navigate(routes.home)
    }
  }, [auth])

  if (auth.activeNavigator === 'signoutRedirect') {
    return (
      <div id="homePage" className="page">
        <h1 className="page-title">{terms.Pages.Auth.title}</h1>

        <p>{terms.Pages.Auth.signoutMessage}</p>

        <Button text={terms.Pages.Auth.reconnect} onClick={() => window.location.reload()} />

      </div>
    )
  }

  return null
}
