/* eslint-disable camelcase */
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { AuthProvider } from 'react-oidc-context'
import { store, persistor } from './reducers/store'
import 'assets/css/constants.scss'
import App from './App'

let manifestHref = '/manifest.json'
if (window.location.hostname === 'localhost' || window.location.hostname.includes('dev')) {
  manifestHref = '/manifest-dev.json'
} else if (window.location.hostname.includes('staging')) {
  manifestHref = '/manifest-staging.json'
}
const link = document.getElementById('manifest-link') as HTMLLinkElement
link.href = manifestHref

const redirect_uri = window.location.hostname === 'localhost' ? 'http://localhost:3000/auth/'
  : `${process.env.REACT_APP_GATEWAY_URL}/auth/`

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  client_secret: process.env.REACT_APP_OIDC_CLIENT_SECRET,
  // client_authentication: 'client_secret_basic', // sur le serveur de dev, il faut utiliser client_secret_post
  client_authentication: 'client_secret_post',
  redirect_uri,
  scope: 'openid profile email roles',
  loadUserInfo: true,
  onSigninCallback: () => { /* do nothing */ },
  accessTokenExpiringNotificationTimeInSeconds: 20,
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <AuthProvider {...oidcConfig}>
        <App />
      </AuthProvider>
    </PersistGate>
  </Provider>,
)
