import { Checkbox } from 'components'
import { updateMissionRD } from 'reducers/rd'
import { handlePatchMissionRD } from 'services'
import { MissionRD } from 'types'
import { useAppDispatch, useAppSelector } from 'utils'

interface Props {
  technicalCenterId: string
  mission: MissionRD
  disabled: boolean
}

export default function OrientationSelection({
  technicalCenterId,
  mission,
  disabled,
}: Props) {
  const dispatch = useAppDispatch()
  const { technicalCenters } = useAppSelector(state => state.params)

  const handleClickOrientation = (
    sens: string,
    motrice: 'principale' | 'secondaire',
  ) => handlePatchMissionRD(
    technicalCenterId,
    mission.id,
    { orientation_motrice: {
      ...mission.orientation_motrice,
      [motrice]: sens,
    } },
    () => {
      dispatch(updateMissionRD({ orientation_motrice: {
        ...mission.orientation_motrice,
        [motrice]: sens,
      } }))
    },
  )

  return (
    <div className="orientation-selection">
      <strong>{`Orientation Motrice 1 ${mission.rame}`}</strong>
      <div className="orientations">
        {technicalCenters.find(tc => tc.id === technicalCenterId)
            && technicalCenters.find(tc => tc.id === technicalCenterId).sens_depart.map(sens => (
              <button
                type="button"
                key={sens.id}
                className="item"
                disabled={disabled}
                onClick={handleClickOrientation(sens.id, 'principale')}
              >
                <Checkbox readonly checked={mission.orientation_motrice?.principale === sens.id} />
                <span>{sens.libelle}</span>
              </button>
            ))}
      </div>
      {mission.um && (
        <>
          <strong>{`Orientation Motrice 1 ${mission.um_details}`}</strong>
          <div className="orientations">
            {technicalCenters.find(tc => tc.id === technicalCenterId)
                && technicalCenters.find(tc => tc.id === technicalCenterId).sens_depart.map(sens => (
                  <button
                    type="button"
                    key={sens.id}
                    className="item"
                    disabled={disabled}
                    onClick={handleClickOrientation(sens.id, 'secondaire')}
                  >
                    <Checkbox readonly checked={mission.orientation_motrice?.secondaire === sens.id} />
                    <span>{sens.libelle}</span>
                  </button>
                ))}
          </div>
        </>
      )}
    </div>
  )
}
