import { MissionTC } from 'types'
import './MissionModele.scss'
import { Tooltip } from '@mui/material'

const getMissionColor = (fluxType: string, isMatch: boolean) => {
  if (fluxType === 'DI' && isMatch) return 'purple'
  if (fluxType === 'OTMT' && isMatch) return 'green'
  if (fluxType === 'OTMT' && !isMatch) return 'orange'
  return 'black'
}
export default function MissionModele({ mission }: {mission: MissionTC}) {
  if (mission.modele) {
    return (
      <span>
        {mission.modele.libelle}
      </span>
    )
  }
  if (mission.gmao) {
    const color = getMissionColor(mission.gmao.type_flux, mission.gmao.is_matching_modele)
    return (
      <Tooltip title={`Numéro d'intervention : ${mission.gmao.num_intervention}`} placement="right">
        <div className={`mission-modele ${color}`}>
          <span className="top-label">Import GMAO</span>
          <div className="label">
            {mission.gmao.is_matching_modele ? mission.gmao.code_operation : mission.gmao.libelle}
          </div>
        </div>
      </Tooltip>
    )
  }
  return null
}
