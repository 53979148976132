import { CerbereUser, MissionAgent } from 'types'
import { useState } from 'react'
import UserSearch from 'components/userSearch/UserSearch'
import terms from 'assets/terms'
import './ModalAddUser.scss'
import { Button, ButtonStyle } from 'components'

interface Props {
  title: string;
  placeholder: string;
  userLabel: string;
  possibleAgents: MissionAgent[];
  handleClose: () => void;
  handleValidate: (selectedUser: CerbereUser, selectedAgent: MissionAgent) => () => void;
}

export default function ModalAddUser({
  title, placeholder, userLabel, handleClose, handleValidate, possibleAgents,
}: Props) {
  const [selectedUser, setSelectedUser] = useState<CerbereUser | null>(null)
  const [selectedAgent, setSelectedAgent] = useState<MissionAgent | null>(null)

  const handleSelectUser = (user: CerbereUser, agent: MissionAgent) => {
    setSelectedUser(user)
    setSelectedAgent(agent)
  }

  return (
    <div className="modal-add-user">
      <h3>{title}</h3>
      <UserSearch
        placeholder={placeholder}
        label={userLabel}
        size="medium"
        handleSelectUser={handleSelectUser}
        possibleAgents={possibleAgents}
        required
      />
      <div className="actions">
        <Button
          text={terms.Common.cancel}
          onClick={handleClose}
          style={ButtonStyle.light}
        />
        <Button
          text={terms.Common.save}
          onClick={handleValidate(selectedUser, selectedAgent)}
          disabled={!selectedUser}
        />
      </div>
    </div>
  )
}
