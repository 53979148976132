import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActiveRole, AppState, GetRolesPayload, SnackbarSeverity } from 'types'

const initialState: AppState = {
  error: null,
  fullscreen: false,
  displaySnackbar: false,
  snackbarMessage: '',
  snackbarSeverity: SnackbarSeverity.SUCCESS,
  loadingUserRoles: true,
  userRoles: [],
  isAdmin: false,
  activeRole: null,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleFullscreen: state => {
      state.fullscreen = !state.fullscreen
    },
    getError: (state, action: PayloadAction<null>) => {
      state.error = action.payload
    },
    setSnackbar: (state, action: PayloadAction<{ message: string, severity: SnackbarSeverity }>) => {
      state.displaySnackbar = true
      state.snackbarMessage = action.payload.message
      state.snackbarSeverity = action.payload.severity
    },
    hideSnackbar: state => {
      state.displaySnackbar = false
      state.snackbarMessage = ''
      state.snackbarSeverity = SnackbarSeverity.SUCCESS
    },
    setActiveRole: (state, action: PayloadAction<ActiveRole | null>) => {
      state.activeRole = action.payload
    },
    setUserRoles: (state, action: PayloadAction<GetRolesPayload>) => {
      state.userRoles = action.payload.access
      state.isAdmin = action.payload.admin
      state.loadingUserRoles = false
    },
  },
})

export const {
  toggleFullscreen,
  getError,
  setSnackbar,
  hideSnackbar,
  setActiveRole,
  setUserRoles,
} = appSlice.actions

export default appSlice.reducer
