import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CerbereState, CerbereUser } from 'types'
import { getUsersFromIds } from './thunks'

const initialState: CerbereState = {
  users: [],
  loadingUsers: false,
  unknownUsers: [],
}

export const cerbereSlice = createSlice({
  name: 'cerbere',
  initialState,
  reducers: {
    setCerbereUsers: (state, action: PayloadAction<CerbereUser[]>) => {
      state.users = action.payload
    },
    addCerbereUser: (state, action: PayloadAction<CerbereUser>) => {
      state.users = [
        ...state.users.filter(user => user.cp !== action.payload.cp),
        action.payload,
      ]
    },
  },
  extraReducers: builder => {
    builder.addCase(getUsersFromIds.pending, (state, action) => {
      state.loadingUsers = true
      const newUsers: CerbereUser[] = action.meta.arg.map((cp: string) => ({
        cp,
        surname: '',
        lastname: '',
        display_name: '',
      }))
      state.users = [...state.users.filter(user => !action.meta.arg.includes(user.cp)), ...newUsers]
    })
    builder.addCase(getUsersFromIds.fulfilled, (state, action) => {
      state.loadingUsers = false
      state.users = [...state.users.filter(user => !action.meta.arg.includes(user.cp)), ...action.payload]
      state.unknownUsers = [
        ...state.unknownUsers.filter(cp => !action.meta.arg.includes(cp)),
        ...action.meta.arg.filter(cp => !action.payload.map(user => user.cp).includes(cp)),
      ]
    })
    builder.addCase(getUsersFromIds.rejected, (state, action) => {
      state.loadingUsers = false
      state.unknownUsers = [
        ...state.unknownUsers.filter(cp => !action.meta.arg.includes(cp)),
        ...action.meta.arg,
      ]
    })
  },
})

export const {
  setCerbereUsers,
  addCerbereUser,
} = cerbereSlice.actions

export default cerbereSlice.reducer
