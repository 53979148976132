import { useState } from 'react'
import terms from 'assets/terms'
import { useAppSelector, usePageRoles } from 'utils'
import { AppRoles, ManageTab } from 'types'
import { Button, ButtonStyle } from 'components'
import TeamTab from './tabs/TeamTab'
import './ManagePage.scss'

const TABS: ManageTab[] = [
  { id: 'teams',
    title: terms.Pages.Manage.tabs.teams,
    desc: terms.Pages.Manage.description.teams },
]

export default function ManagePage() {
  usePageRoles([AppRoles.manager])
  const { activeRole } = useAppSelector(state => state.app)
  const [activeTab, setActiveTab] = useState<ManageTab>(TABS[0])

  const handleClickTab = (tab: ManageTab) => () => {
    setActiveTab(tab)
  }

  const renderTabContent = (tab: string) => {
    if (!activeRole || activeRole.role === AppRoles.admin) return null
    const technicalCenterId = activeRole.technicenterId
    switch (tab) {
      case 'teams':
        return (<TeamTab technicalCenterId={technicalCenterId} />)
      default:
        return null
    }
  }

  return (
    <div id="manage-page" className="page">
      <div className="manage-side-menu">
        <h2>{terms.Pages.Manage.title}</h2>
        <div className="tabs">
          {TABS.map(tab => (
            <Button
              key={tab.id}
              text={tab.title}
              style={activeTab.id === tab.id ? ButtonStyle.menuSelected : ButtonStyle.menuBorderLess}
              onClick={handleClickTab(tab)}
            />
          ))}
        </div>
      </div>
      <div className="manage-form">
        <div className="form-header">
          <h2>{activeTab.title}</h2>
          <span>{activeTab.desc}</span>
        </div>
        {renderTabContent(activeTab.id)}
      </div>

    </div>
  )
}
