export const routes = {
  home: '/',
  homeAlt: '/accueil',
  params: '/params',
  manage: '/gestion',
  missionTable: '/tableau-missions',
  rd: '/rd',
  rdMissionsList: '/missions-list',
  rdMissionInProgress: '/mission-in-progress',
  auth: '/auth',
}
