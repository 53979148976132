import { Tooltip } from '@mui/material'
import { AdditionnalMissionTC, AppRoles, MissionStatus, MissionTC } from 'types'
import { handlePatchAdditionnalMissionsTC } from 'services'
import { ModalPatchMissionSelectField, modalSignal } from 'components'
import { useAppDispatch, useAppSelector } from 'utils'
import { getMissionsTC } from 'reducers/missionTable/thunks'
import ActionWrapper from '../actionWrapper/ActionWrapper'

interface Props {
  mission: MissionTC
  technicalCenterId: string
  missionsOrdering: string
}

const getLabel = (m: AdditionnalMissionTC, status: MissionStatus) => {
  if (m.gmao && status === MissionStatus.validated) {
    return (
      <>
        <span style={{
          fontSize: '16px',
          marginRight: '5px',
          fontWeight: 'bold',
          color: m.statut_identique_after_validation ? '#008636' : '#D32F2F',
        }}
        >
          G
        </span>
        {m.gmao.is_matching_modele
          ? (
            <Tooltip title={`Numéro d'intervention : ${m.gmao.num_intervention}`} placement="right">
              <span>
                {m.gmao.code_operation}
              </span>
            </Tooltip>
          )
          : (m.gmao.libelle)}
      </>
    )
  }
  if (m.modele && m.modele.libelle) return m.modele.libelle
  if (m.gmao && m.gmao.is_matching_modele && m.gmao.code_operation) {
    return `${m.gmao.code_operation} - ${m.gmao.num_intervention}`
  }
  if (m.gmao && !m.gmao.is_matching_modele && m.gmao.libelle) return m.gmao.libelle
  return 'Mission inconnue'
}

export default function MissionAdditionnalMissions({
  mission, technicalCenterId, missionsOrdering,
}: Props) {
  const dispatch = useAppDispatch()
  const { activeRole } = useAppSelector(state => state.app)
  return (
    <ActionWrapper
      actions={[
        {
          title: 'Modifier',
          available: [MissionStatus.inProgress, MissionStatus.validated].includes(mission.statut)
                        && mission.missions_supplementaires.length > 0 && activeRole.role === AppRoles.com,
          onClick: () => {
            modalSignal.value = (
              <ModalPatchMissionSelectField
                title="Missions supplémentaires"
                handleClose={() => { modalSignal.value = null }}
                handleValidate={newMissions => handlePatchAdditionnalMissionsTC(
                  technicalCenterId,
                  mission.id,
                  mission.missions_supplementaires.map(m => ({
                    id: m.id,
                    done: newMissions.map(ms => ms.id).includes(m.id),
                  })),
                  () => {
                    modalSignal.value = null
                    dispatch(getMissionsTC({
                      technicenterId: technicalCenterId,
                      ordering: missionsOrdering,
                    }))
                  },
                )}
                options={mission.missions_supplementaires.map(m => ({
                  id: m.id,
                  // eslint-disable-next-line no-nested-ternary
                  libelle: m.modele ? m.modele?.libelle || m.gmao?.libelle : m.gmao?.is_matching_modele
                    ? `${m.gmao?.code_operation} - ${m.gmao?.num_intervention}` : m.gmao?.libelle,
                }))}
                selectedOptions={mission.missions_supplementaires.filter(m => m.done).map(
                  m => ({ id: m.id, libelle: m.modele?.libelle || m.gmao?.libelle }),
                )}
              />
            )
          },
        },
      ]}
    >
      {mission?.missions_supplementaires.map(m => (
        <p
          key={m?.id}
          style={{
            ...(m?.done ? { color: '#008636' } : { color: '#000' }),
            ...(mission.statut === MissionStatus.validated && !m?.done ? { color: '#D32F2F' } : {}),
          }}
        >
          {getLabel(m, mission.statut)}
        </p>
      ))}
      {mission.missions_supplementaires.length === 0 && '-'}
    </ActionWrapper>
  )
}
