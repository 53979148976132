import { ArrowForward, PeopleOutlined, PersonOutlined } from '@mui/icons-material'
import terms from 'assets/terms'
import { Button, ButtonStyle, Checkbox } from 'components'
import { useState } from 'react'
import { MissionAgent, MissionTeam } from 'types'
import { useCerbereUsers } from 'utils'

import './ModalManageTeam.scss'
import { handleUpdateAgents } from 'services'

interface Props {
  technicenterId: string
  technicenterTeams: MissionTeam[]
  technicenterAgents: MissionAgent[]
  handleClose: () => void
}

const localTerms = terms.Modals.MissionManageTeam

export default function MissionTable({
  technicenterId, technicenterTeams, technicenterAgents, handleClose,
}: Props) {
  const { getUserName } = useCerbereUsers(technicenterAgents.map(agent => agent.user_id))
  const [selectedTeamId, setSelectedTeamId] = useState<string | null>(null)
  const [activeAgents, setActiveAgents] = useState<string[]>(
    technicenterAgents.filter(agent => agent.active).map(agent => agent.id),
  )

  const handleChangeAgentStatus = (agentId: string, newStatus: boolean) => () => {
    setActiveAgents(prev => {
      if (!newStatus) return prev.filter(id => id !== agentId)
      return [...prev.filter(id => id !== agentId), agentId]
    })
  }

  const handleClickTeam = (teamId: string) => () => {
    const teamAgents = technicenterAgents.filter(agent => agent.equipe === teamId)
    const allActive = teamAgents.every(agent => activeAgents.includes(agent.id))
    if (allActive) {
      teamAgents.forEach(agent => {
        handleChangeAgentStatus(agent.id, false)()
      })
    } else {
      teamAgents.forEach(agent => {
        handleChangeAgentStatus(agent.id, true)()
      })
    }
  }

  return (
    <div className="modal-manage-team">
      <div className="header">
        <PeopleOutlined />
        <h2>{localTerms.title}</h2>
        <Button text={terms.Common.cancel} style={ButtonStyle.light} onClick={handleClose} />
        <Button
          text={terms.Common.save}
          onClick={handleUpdateAgents(technicenterId, technicenterAgents, activeAgents, handleClose)}
        />
      </div>
      <div className="divider" />
      <span className="content-title">{localTerms.selectTeam}</span>
      <div className="agent-selection">
        <ul className="teams">
          {technicenterTeams.filter(team => team.agents.length > 0).map(team => (
            <li
              key={team.id}
              className={`item-team ${selectedTeamId === team.id ? 'selected' : ''}`}
            >
              <button type="button" onClick={() => setSelectedTeamId(team.id)}>
                <Checkbox
                  id={team.id}
                  checked={technicenterAgents
                    .filter(agent => agent.equipe === team.id).every(agent => activeAgents.includes(agent.id))}
                  indeterminate={technicenterAgents
                    .filter(agent => agent.equipe === team.id).some(agent => activeAgents.includes(agent.id))}
                  onChange={handleClickTeam(team.id)}
                />
                <span>{team.libelle}</span>
                <span className="agents-count">{team.agents.length}</span>
                <PersonOutlined />
              </button>
              <ArrowForward className={selectedTeamId === team.id ? 'visible' : 'hidden'} />
            </li>
          ))}
        </ul>
        <ul className="agents">
          <div className="title">
            {selectedTeamId ? (
              <>
                <PeopleOutlined />
                <span>
                  {technicenterAgents.some(
                    agent => agent.equipe === selectedTeamId && activeAgents.includes(agent.id),
                  ) ? (`${technicenterAgents
                      .filter(a => a.equipe === selectedTeamId && activeAgents.includes(a.id)).length
                    } ${localTerms.selectedPeople}`) : localTerms.noPeopleSelected}
                </span>
              </>
            ) : (
              <span>{localTerms.noTeam}</span>
            )}
          </div>
          {technicenterAgents.filter(agent => (agent.equipe === selectedTeamId) && selectedTeamId).map(agent => (
            <li key={agent.id} className="item-agent">
              <button
                type="button"
                onClick={handleChangeAgentStatus(agent.id, !activeAgents.includes(agent.id))}
              >
                <Checkbox
                  id={agent.id}
                  checked={activeAgents.includes(agent.id)}
                  readonly
                />
                <span>
                  {getUserName(agent.user_id)}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
