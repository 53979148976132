export const parseErrorMessage = (
  error: { response?: { data?: Record<string, string[]> | string } } | Error | string,
  defaultMessage: string,
) => {
  if (typeof error === 'string') {
    return error
  }
  if (error instanceof Error) {
    return error?.message || defaultMessage
  }
  if (typeof error?.response?.data === 'string') {
    return error.response.data
  }
  if (error?.response?.data) {
    return (Object.values(error.response.data) as string[][]).flatMap(s => s).join('. ')
  }
  return defaultMessage
}
