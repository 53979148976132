import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ManageState, ManageTeam } from 'types'
import { getTeams } from './thunks'

const initialState: ManageState = {
  teams: [],
  teamsLoading: false,
}

export const manageSlice = createSlice({
  name: 'manage',
  initialState,
  reducers: {
    clearManageState: () => initialState,
    postNewTeam: (state, action: PayloadAction<ManageTeam>) => {
      state.teams = [
        ...state.teams.filter(team => team.id !== action.payload.id),
        { agents: [], ...action.payload },
      ]
    },
    deleteTeam: (state, action: PayloadAction<string>) => {
      state.teams = state.teams.filter(team => team.id !== action.payload)
    },
    addTeamMember: (state, action: PayloadAction<{ teamId: string; agent: {cp: string, id: string} }>) => {
      state.teams = state.teams.map(team => {
        if (team.id === action.payload.teamId) {
          return { ...team, agents: [...team.agents, action.payload.agent] }
        }
        return team
      })
    },
    removeTeamMember: (state, action: PayloadAction<{ teamId: string; agentId: string }>) => {
      state.teams = state.teams.map(team => {
        if (team.id === action.payload.teamId) {
          return { ...team, agents: team.agents.filter(agent => agent.id !== action.payload.agentId) }
        }
        return team
      })
    },
  },
  extraReducers: builder => {
    builder.addCase(getTeams.pending, state => {
      state.teamsLoading = true
    })
    builder.addCase(getTeams.fulfilled, (state, action) => {
      state.teams = action.payload
      state.teamsLoading = false
    })
    builder.addCase(getTeams.rejected, state => {
      state.teamsLoading = false
    })
  },
})

export const {
  clearManageState,
  postNewTeam,
  deleteTeam,
  addTeamMember,
  removeTeamMember,
} = manageSlice.actions

export default manageSlice.reducer
