import { ReactElement, StrictMode, Suspense, useEffect, useState } from 'react'
import {
  BrowserRouter, Navigate, Route, Routes, useLocation,
} from 'react-router-dom'
import '@preact/signals-react/auto'
import { APP_NAME, get, useAppDispatch, useAppSelector } from 'utils'
import { terms } from 'assets/terms'
import { hideSnackbar, setUserRoles } from 'reducers/app'
import {
  Loader, TopBar, SnackMessage, SideMenu, ModalWrapper, modalSignal,
  NotificationsWrapper,
} from 'components'
import {
  DeniedPage, HomePage, ParamsPage, ManagePage, MissionTablePage, RdPage,
  RdMissionsList,
  RdMissionDetail,
} from 'pages'

import 'App.scss'
import { routes } from 'services'
import { GetRolesPayload } from 'types'
import { useAuth } from 'react-oidc-context'
import AuthPage from 'pages/auth/AuthPage'

/** Externalize router logic in its own component
 * in order to use useLocation hook
 */
function Router() {
  const location = useLocation()

  useEffect(() => {
    // force hiding modal on route change

    modalSignal.value = undefined
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<AuthPage />} path={routes.auth} />
      <Route element={<HomePage />} path={routes.home} />
      <Route element={<HomePage />} path={routes.homeAlt} />
      <Route element={<ParamsPage />} path={routes.params} />
      <Route element={<ManagePage />} path={routes.manage} />
      <Route element={<MissionTablePage />} path={routes.missionTable} />
      <Route element={<RdPage />} path={routes.rd} />
      <Route element={<RdMissionsList />} path={`${routes.rd}${routes.rdMissionsList}/:statut`} />
      <Route
        element={<RdMissionDetail />}
        path={`${routes.rd}${routes.rdMissionsList}/:statut/:id`}
      />
      <Route path="*" element={<Navigate to={routes.home} />} />
    </Routes>
  )
}

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const auth = useAuth()
  const { displaySnackbar, snackbarMessage, snackbarSeverity, loadingUserRoles } = useAppSelector(state => state.app)
  const [hasTriedSignin, setHasTriedSignin] = useState(false)
  const [hasFetchRoles, setHasFetchRoles] = useState(false)
  const [hasToken, setHasToken] = useState(false)

  useEffect(() => {
    if (!auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect()
      setHasTriedSignin(true)
    } else if (auth.isAuthenticated && !auth.isLoading && !hasFetchRoles && hasToken) {
      setHasFetchRoles(true)
      get<GetRolesPayload>('/r2d2/roles/').then(response => {
        dispatch(setUserRoles(response))
      })
    }
  }, [auth, hasTriedSignin, hasToken])

  useEffect(() => {
    if (auth?.user?.profile?.id_token) {
      localStorage.setItem('access_token', auth.user.profile.id_token as string)
      setHasToken(true)
    }
  }, [auth])

  useEffect(() => auth.events.addSilentRenewError(() => {
    setTimeout(() => {
      auth.signinRedirect()
    }, 10000)
  }), [auth.events, auth.signinRedirect])

  const renderApp = () => {
    if (!auth.isLoading && !auth.isAuthenticated) return <DeniedPage />
    if (auth.activeNavigator === 'signoutRedirect') return <AuthPage />
    if (auth.isLoading || loadingUserRoles) return <Loader message={terms.Common.loading} standalone />
    return (
      <>
        <Router />
        <ModalWrapper />
        <SideMenu />
        <NotificationsWrapper />
      </>
    )
  }

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <TopBar enableSideMenu={false} appName={APP_NAME} hasAccess={!auth.isAuthenticated} />
          <div id="app">{renderApp()}</div>
          <SnackMessage
            message={snackbarMessage}
            severity={snackbarSeverity}
            displaySnackbar={displaySnackbar}
            handleClose={() => dispatch(hideSnackbar())}
          />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
