import { CerbereUser } from 'types'
import { createApiThunk, post } from 'utils'

const getUsersFromIds = createApiThunk(
  'cerbere/getUsersFromId',
  (ids: string[]) => post<CerbereUser[]>('/r2d2/users/simple/', ids.filter(Boolean)),
)

export {
  getUsersFromIds,
}
