import { useEffect, useState } from 'react'
import {
  Button, ModalAddMissionTC, modalSignal, ButtonStyle,
  ModalMissionFilters,
  ModalConfirm, NotificationsStream,
  ModalBatchExport,
} from 'components'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import { handleMissionBatchAction } from 'services'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import TuneIcon from '@mui/icons-material/Tune'
import { useAppDispatch, useAppSelector } from 'utils'
import { signal } from '@preact/signals-core'
import { AppRoles, MISSION_ARCHIVE_STATUS, MISSION_DELETE_STATUS, MissionBatchAction, MissionTC } from 'types'
import { getMissionsTC } from 'reducers/missionTable/thunks'
import { notificationsMessagesSignal } from 'components/notifications/NotificationWrapper'
import { notificationsDrawerSignal } from '../notificationsDrawer/NotificationsDrawer'

interface Props {
  technicalCenterId: string
  selectedMissions: string[]
  setSelectedMissions: (selectedMissions: string[]) => void
  missionsOrdering: string
}

export const missionListSignal = signal<MissionTC[]>([])

export default function Header({
  technicalCenterId, selectedMissions, setSelectedMissions, missionsOrdering,
}: Props) {
  const dispatch = useAppDispatch()
  const { activeRole } = useAppSelector(state => state.app)
  const { missionsTC, missionsPreAssigned, filters } = useAppSelector(state => state.missionTable)
  const [preAffectedMissionsTC, setPreAffectedMissionsTC] = useState(false)
  const handleAddMission = () => {
    modalSignal.value = (
      <ModalAddMissionTC
        title="Nouvelle mission"
        handleClose={() => { modalSignal.value = null }}
        ordering={missionsOrdering}
        technicalCenterId={technicalCenterId}
      />
    )
  }

  const toggleDrawer = (newOpen: boolean) => () => {
    notificationsDrawerSignal.value = newOpen
  }

  const handleChangeFilters = () => {
    modalSignal.value = (
      <ModalMissionFilters
        technicenterId={technicalCenterId}
        filters={filters}
        handleClose={() => { modalSignal.value = null }}
        displayStatus={!preAffectedMissionsTC}
        ordering={missionsOrdering}
      />
    )
  }

  const handleBatchAssign = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir affecter ces missions ?"
        handleValidate={handleMissionBatchAction(
          technicalCenterId,
          selectedMissions,
          () => {
            modalSignal.value = null
            setSelectedMissions([])
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
          },
          MissionBatchAction.assign,
        )}
        handleClose={() => { modalSignal.value = null }}
      />
    )
  }

  const handleBatchExport = () => {
    modalSignal.value = (
      <ModalBatchExport
        technicalCenterId={technicalCenterId}
        selectedMissions={selectedMissions}
        handleClose={() => { modalSignal.value = null }}
      />
    )
  }

  const handleBatchDelete = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir supprimer ces missions ?"
        handleValidate={handleMissionBatchAction(
          technicalCenterId,
          selectedMissions,
          () => {
            modalSignal.value = null
            setSelectedMissions([])
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
          },
          MissionBatchAction.delete,
        )}
        handleClose={() => { modalSignal.value = null }}
      />
    )
  }

  const handleBatchArchive = () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir archiver ces missions ?"
        handleValidate={handleMissionBatchAction(
          technicalCenterId,
          selectedMissions,
          () => {
            modalSignal.value = null
            setSelectedMissions([])
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
          },
          MissionBatchAction.archive,
        )}
        handleClose={() => { modalSignal.value = null }}
      />
    )
  }

  useEffect(() => {
    setSelectedMissions([])
    if (preAffectedMissionsTC) {
      missionListSignal.value = missionsPreAssigned
    } else {
      missionListSignal.value = missionsTC
    }
  }, [preAffectedMissionsTC, missionsTC, missionsPreAssigned])

  const cantAssignMission = (missionid: string) => {
    const mission = missionsPreAssigned.find(m => m.id === missionid)
    if (!mission) return true
    const requiredFields = ['agent']
    if (mission.modele.chantier_arrivee) requiredFields.push('chantier_arrivee')
    if (mission.modele.chantier_depart) requiredFields.push('chantier_depart')
    if (mission.modele.voie_arrivee) requiredFields.push('voie_arrivee')
    if (mission.modele.voie_depart) requiredFields.push('voie_depart')
    if (mission.modele.sens_depart) requiredFields.push('sens_depart')
    return requiredFields.some(field => !mission[field])
  }

  const missionTypeCount = filters.gmao_modeles ? filters.mission_modeles.length + 1 : filters.mission_modeles.length
  const missionTypeSelectedCount = filters.gmao_modeles && filters.gmao_modele_else_selected
    ? filters.mission_modeles_selected.length + 1 : filters.mission_modeles_selected.length

  return (
    <div className="mt-header">
      <div className="mt-sub-header-1">
        <div className="mt-switch-buttons">
          <Button
            endIcon={(
              <Avatar sx={{ width: 24, height: 24, fontSize: 12, bgcolor: '#256EFF' }}>{missionsTC.length}</Avatar>
          )}
            text="Demandes"
            style={!preAffectedMissionsTC ? ButtonStyle.white : ButtonStyle.transparent}
            onClick={() => setPreAffectedMissionsTC(false)}
            disabled={activeRole?.role !== AppRoles.com}
          />
          {activeRole?.role === AppRoles.com && (
          <Button
            endIcon={(
              <Avatar sx={{ width: 24, height: 24, fontSize: 12, bgcolor: '#256EFF' }}>
                {missionsPreAssigned.length}
              </Avatar>
          )}
            text="Pré-affectations"
            style={preAffectedMissionsTC ? ButtonStyle.white : ButtonStyle.transparent}
            onClick={() => setPreAffectedMissionsTC(true)}
          />
          )}
        </div>
        <div className="mt-notifications" onClick={toggleDrawer(true)}>
          <NotificationsStream centerId={technicalCenterId} missionsOrdering={missionsOrdering} />
          <Badge badgeContent={notificationsMessagesSignal.value.filter(m => !m.vu).length} color="error">
            <NotificationsOutlinedIcon />
          </Badge>
          Notifications
        </div>
      </div>
      <div className="mt-sub-header-2">
        <Button text="Filtres" startIcon={<TuneIcon />} onClick={handleChangeFilters} />
        <span className="filter-recap">
          {`Agent (${filters.agents_selected.length}/${filters.agents.length})`}
          {` - Missions (${missionTypeSelectedCount}/${missionTypeCount})`}
          {!preAffectedMissionsTC && ` - Statut (${filters.statuts_selected.length}/${filters.statuts.length})`}
        </span>
        <div className="action-buttons">
          {activeRole?.role === AppRoles.com && (
            <Button text="Nouvelle mission" onClick={handleAddMission} />
          )}
          {selectedMissions.length > 0 && preAffectedMissionsTC && activeRole.role === AppRoles.com && (
            <Button
              text="Affecter"
              onClick={handleBatchAssign}
              style={ButtonStyle.success}
              disabled={selectedMissions.some(cantAssignMission)}
            />
          )}
          {selectedMissions.length > 0 && (
            <Button text="Export Excel" onClick={handleBatchExport} style={ButtonStyle.light} />
          )}
          {selectedMissions.length > 0 && !preAffectedMissionsTC && activeRole.role === AppRoles.com && (
            <Button
              text="Supprimer"
              onClick={handleBatchDelete}
              style={ButtonStyle.danger}
              disabled={selectedMissions.map(missionId => missionsTC.find(m => m.id === missionId))
                .some(mission => !MISSION_DELETE_STATUS.includes(mission?.statut))}
            />
          )}
          {selectedMissions.length > 0 && preAffectedMissionsTC && activeRole.role === AppRoles.com && (
            <Button
              text="Supprimer"
              onClick={handleBatchDelete}
              style={ButtonStyle.danger}
              disabled={selectedMissions.map(missionId => missionsPreAssigned.find(m => m.id === missionId))
                .some(mission => !MISSION_DELETE_STATUS.includes(mission?.statut))}
            />
          )}
          {selectedMissions.length > 0 && !preAffectedMissionsTC && activeRole.role === AppRoles.com && (
            <Button
              text="Archiver"
              onClick={handleBatchArchive}
              disabled={selectedMissions.map(missionId => missionsTC.find(m => m.id === missionId))
                .some(mission => !MISSION_ARCHIVE_STATUS.includes(mission?.statut))}
            />
          )}
        </div>
      </div>
    </div>
  )
}
