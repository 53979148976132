/* export utils here */
export * from './thunks'
export * from './hooks'
export * from './errors'
export * from './dnd'
export * from './missionTable'
export * from './requests'

export const APP_NAME = 'R2D2'

export const delay = millis => new Promise<void>(resolve => {
  setTimeout(() => resolve(), millis)
})
