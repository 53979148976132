// import svg as React Component
import { ReactComponent as Warning } from 'assets/logos/warning.svg'
import terms from 'assets/terms'
import './ModalConfirm.scss'
import { Button, ButtonStyle } from 'components'
import { useState } from 'react'

interface Props {
  title: string;
  description?: string;
  handleClose: () => void;
  handleValidate: () => void | Promise<void>;
}

export default function ModalConfirm(
  { title, description = '', handleClose, handleValidate }: Props,
) {
  const [pending, setPending] = useState(false)
  const onClick = () => {
    setPending(true)
    Promise.resolve(handleValidate()).finally(() => setPending(false))
  }
  return (
    <div className="modal-confirm">
      <div className="warning-icon">
        <Warning />
      </div>
      <h3>{title}</h3>
      {description && <h4>{description}</h4>}
      <div className="actions">
        <Button text={terms.Common.cancel} onClick={handleClose} style={ButtonStyle.light} />
        <Button text={terms.Common.continue} onClick={onClick} disabled={pending} />
      </div>
    </div>
  )
}
