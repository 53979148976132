import { get, createApiThunk } from 'utils'
import { ManageTeam } from 'types'

const getTeams = createApiThunk(
  'r2d2/getTeams',
  (technicenterId: string) => get<ManageTeam[]>(`/r2d2/technicentres/${technicenterId}/equipe/`),
)

export {
  getTeams,
}
