import { useEffect, useMemo, useState } from 'react'
import * as moment from 'moment'
import {
  delay, useAppDispatch, useAppSelector, useMountEffect, get, post,
} from 'utils'
import { SelectChangeEvent } from '@mui/material'
import { GMAOModele, GMAOStatus, MissionOption, MissionTC, SnackbarSeverity } from 'types'
import { setSnackbar } from 'reducers/app'

export interface FormValues {
  rame: string;
  um: boolean;
  um_details: string;
  modele?: string;
  gmao?: string;
  prioritaire: boolean;
  chantier_depart: string;
  chantier_arrivee: string;
  voie_depart: string;
  voie_arrivee: string;
  heure_debut_theorique: moment.Moment;
  heure_fin_theorique: moment.Moment;
  agent: string;
  sens_depart: string;
  via: string;
  missions_supplementaires: string[];
  observation_com: string;
  pancartage: string;
  statut: string;
}

export const emptyFormValues: FormValues = {
  rame: '',
  um: false,
  um_details: '',
  modele: '',
  prioritaire: false,
  chantier_depart: '',
  chantier_arrivee: '',
  voie_depart: '',
  voie_arrivee: '',
  heure_debut_theorique: moment(),
  heure_fin_theorique: moment(),
  agent: '',
  sens_depart: '',
  via: '',
  missions_supplementaires: [],
  observation_com: '',
  pancartage: '',
  statut: 'PREAFFECTEE',
}

const sortMissionOptions = (a: MissionOption, b: MissionOption) => {
  let aValue
  let bValue
  if (a.gmao) {
    if (a.gmao.type_flux === 'DI' && a.gmao.is_matching_modele) aValue = 1
    else if (a.gmao.type_flux === 'OTMT' && a.gmao.is_matching_modele) aValue = 3
    else if (a.gmao.type_flux === 'OTMT' && !a.gmao.is_matching_modele) aValue = 2
    else aValue = 0
  } else {
    aValue = -1
  }
  if (b.gmao) {
    if (b.gmao.type_flux === 'DI' && b.gmao.is_matching_modele) bValue = 1
    else if (b.gmao.type_flux === 'OTMT' && b.gmao.is_matching_modele) bValue = 3
    else if (b.gmao.type_flux === 'OTMT' && !b.gmao.is_matching_modele) bValue = 2
    else bValue = 0
  } else {
    bValue = -1
  }
  if (aValue === bValue) return a.libelle.localeCompare(b.libelle)
  return bValue - aValue
}

const useHandlers = (
  technicalCenterId: string,
  defaultValues?: FormValues,
  patchMissionGMAO?: GMAOModele,
  patchAdditionnalMissions?: MissionTC['missions_supplementaires'],
) => {
  const dispatch = useAppDispatch()
  const [formValues, setFormValues] = useState<FormValues>(defaultValues || emptyFormValues)
  const { missions, technicalCenters, additionalMissions } = useAppSelector(state => state.params)
  const [gmaoModeles, setGmaoModeles] = useState<GMAOModele[]>([])
  const [pendingGmao, setPendingGmao] = useState(false)

  const missionsOptions: MissionOption[] = useMemo(() => [
    ...missions.map(mission => ({
      id: mission.id,
      libelle: mission.libelle,
      gmao: null,
    })),
    ...gmaoModeles.map(mission => ({
      id: mission.id,
      libelle: mission.is_matching_modele ? `${mission.code_operation} - ${mission.num_intervention}` : mission.libelle,
      gmao: mission,
    })),
  ], [missions, gmaoModeles])

  const additionalMissionsOptions: MissionOption[] = useMemo(() => [
    ...additionalMissions.map(mission => ({
      id: mission.id,
      libelle: mission.libelle,
      gmao: null,
    })),
    ...gmaoModeles.map(mission => ({
      id: mission.id,
      libelle: mission.is_matching_modele ? `${mission.code_operation} - ${mission.num_intervention}` : mission.libelle,
      gmao: mission,
    })),
  ].filter(opt => opt.id !== formValues?.gmao), [additionalMissions, gmaoModeles, formValues?.gmao])

  const sortedMissionsOptions = useMemo(() => missionsOptions.sort(sortMissionOptions), [missionsOptions])

  const sortedAdditionalMissionsOptions = useMemo(() => additionalMissionsOptions
    .sort(sortMissionOptions), [additionalMissionsOptions])

  const handleSelectModele = (modeleId: string) => {
    const selectedOption = missionsOptions.find(mission => mission.id === modeleId)
    if (!selectedOption) return
    if (selectedOption.gmao) {
      const selectedMission = gmaoModeles.find(modele => modele.id === modeleId)
      if (!selectedMission) return
      setFormValues({
        ...formValues,
        modele: '',
        gmao: modeleId,
        rame: selectedMission.rame,
        chantier_depart: '',
        chantier_arrivee: '',
        voie_depart: '',
        voie_arrivee: '',
        sens_depart: '',
        via: '',
        heure_debut_theorique: moment(),
        heure_fin_theorique: moment().add(selectedMission.duree_theorique, 'minutes'),
      })
    } else {
      const selectedMission = missions.find(modele => modele.id === modeleId)
      if (!selectedMission) return
      setFormValues({
        ...formValues,
        modele: modeleId,
        gmao: '',
        chantier_depart: '',
        chantier_arrivee: '',
        voie_depart: '',
        voie_arrivee: '',
        sens_depart: '',
        via: '',
        heure_debut_theorique: moment(),
        heure_fin_theorique: moment().add(selectedMission.duree_theorique, 'minutes'),
      })
    }
  }

  useMountEffect(() => {
    if (defaultValues?.rame && !pendingGmao) {
      const getGmaoStatus = () => post<string>(
        `/r2d2/technicentres/${technicalCenterId}/gmao/`,
        { rame: defaultValues.rame },
      )
      setGmaoModeles([])
      setPendingGmao(true)
      const fetchStatus = statusId => get<GMAOStatus>(
        `/r2d2/technicentres/${technicalCenterId}/gmao/statut/${statusId}/`,
      )
        .then(status => {
          if (status.status === 'PENDING') {
            return delay(500).then(() => fetchStatus(statusId))
          } if (status.status === 'FAILURE') {
            return Promise.reject(Error('Erreur lors de la récupération des missions GMAO'))
          } if (status.status === 'SUCCESS OUTPUT' || status.status === 'SUCCESS WITHOUT OUTPUT') {
            get<GMAOModele[]>(`/r2d2/technicentres/${technicalCenterId}/gmao/`, {
              MR: defaultValues.rame,
            })
              .then(modeles => {
                setGmaoModeles(modeles)
                setPendingGmao(false)

                if (patchMissionGMAO) {
                  const newId = modeles.find(m => m.code_operation === patchMissionGMAO.code_operation
                     && m.num_intervention === patchMissionGMAO.num_intervention)?.id
                  if (newId) {
                    setFormValues(prev => ({
                      ...prev,
                      gmao: newId,
                    }))
                  } else {
                    handleSelectModele('')
                    dispatch(setSnackbar({
                      message: 'La mission GMAO n\'a pas été trouvée',
                      severity: SnackbarSeverity.WARNING,
                    }))
                  }
                }

                if (patchAdditionnalMissions) {
                  const regularIds = patchAdditionnalMissions.filter(m => m?.modele).map(m => m.modele?.id)
                  const gmaoMissions = patchAdditionnalMissions.filter(m => m?.gmao).map(m => m.gmao)
                  const newIds = gmaoMissions.map(gmaoMission => {
                    const newId = modeles.find(m => m.code_operation === gmaoMission.code_operation
                      && m.num_intervention === gmaoMission.num_intervention)?.id
                    return newId || null
                  })
                  const newMissionsIds = [
                    ...regularIds,
                    ...newIds.filter(Boolean),
                  ]
                  setFormValues(prev => ({
                    ...prev,
                    missions_supplementaires: newMissionsIds,
                  }))
                  if (newMissionsIds.length !== patchAdditionnalMissions.length) {
                    dispatch(setSnackbar({
                      message: 'Certaines missions supplémentaires GMAO n\'ont pas été trouvées',
                      severity: SnackbarSeverity.WARNING,
                    }))
                  }
                }
              })
          }
          setPendingGmao(false)
          return Promise.resolve()
        })
      getGmaoStatus().then(statusId => fetchStatus(statusId))
    }
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement
| { name?: string | undefined; value: unknown }>) => {
    const { name, value } = e.target
    if (name === 'voie_depart') {
      const selectedChantier = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
        chantier => chantier.voies.find(voie => voie.id === value),
      )
      if (selectedChantier) {
        setFormValues({
          ...formValues,
          voie_depart: value as string,
          chantier_depart: selectedChantier.id,
        })
        return
      }
    } else if (name === 'voie_arrivee') {
      const selectedChantier = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
        chantier => chantier.voies.find(voie => voie.id === value),
      )
      if (selectedChantier) {
        setFormValues({
          ...formValues,
          voie_arrivee: value as string,
          chantier_arrivee: selectedChantier.id,
        })
        return
      }
    } else if (name === 'chantier_depart') {
      setFormValues({
        ...formValues,
        chantier_depart: value as string,
        voie_depart: '',
      })
      return
    } else if (name === 'chantier_arrivee') {
      setFormValues({
        ...formValues,
        chantier_arrivee: value as string,
        voie_arrivee: '',
      })
      return
    }
    setFormValues({
      ...formValues,
      [name as string]: value,
    })
  }

  const handleCheckboxChange = (name: string) => () => {
    if (name === 'um' && formValues.um) {
      setFormValues({
        ...formValues,
        um: false,
        um_details: '',
      })
    } else {
      setFormValues({
        ...formValues,
        [name]: !formValues[name as keyof FormValues],
      })
    }
  }

  const handleTimeChange = (name: string, value: moment.Moment) => {
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleMultiSelectChange = (e: SelectChangeEvent<string[]>) => {
    const selectedValue = e.target.value
    if (typeof selectedValue === 'string') return
    setFormValues({
      ...formValues,
      missions_supplementaires: selectedValue,
    })
  }

  const handleChangeRame = async (value: string) => {
    setFormValues({
      ...formValues,
      rame: value,
    })
    const getGmaoStatus = () => post<string>(`/r2d2/technicentres/${technicalCenterId}/gmao/`, { rame: value })
    setGmaoModeles([])
    const fetchStatus = statusId => get<GMAOStatus>(
      `/r2d2/technicentres/${technicalCenterId}/gmao/statut/${statusId}/`,
    )
      .then(status => {
        if (status.status === 'PENDING') {
          return delay(500).then(() => fetchStatus(statusId))
        } if (status.status === 'FAILURE') {
          return Promise.reject(Error('Erreur lors de la récupération des missions GMAO'))
        } if (status.status === 'SUCCESS OUTPUT') {
          get<GMAOModele[]>(`/r2d2/technicentres/${technicalCenterId}/gmao/`, {
            MR: value,
          })
            .then(setGmaoModeles)
        }
        return Promise.resolve()
      })

    return getGmaoStatus().then(statusId => fetchStatus(statusId))
  }

  return {
    formValues,
    handleInputChange,
    handleCheckboxChange,
    handleTimeChange,
    handleMultiSelectChange,
    handleChangeRame,
    sortedMissionsOptions,
    sortedAdditionalMissionsOptions,
    handleSelectModele,
    pendingGmao,
  }
}

const initialRequiredFields = ['rame', 'heure_debut_theorique', 'heure_fin_theorique']
const useRequiredFields = (formValues: FormValues, missionsOptions: MissionOption[]) => {
  const [requiredFields, setRequiredFields] = useState(initialRequiredFields)
  const { missions } = useAppSelector(state => state.params)
  const [canEditRequiredFields, setCanEditRequiredFields] = useState(true)

  // --- Change required fields based on chosen mission modele ---
  useEffect(() => {
    const selectedMission = missions.find(mission => mission.id === formValues.modele)
    const selectedGMAOMission = missionsOptions.find(mission => mission.id === formValues.gmao)
    if (selectedMission) {
      setRequiredFields([
        ...initialRequiredFields,
        'modele',
        ...(Object.entries(selectedMission).filter(([, value]) => value === true).map(([key]) => key)),
      ])
      setCanEditRequiredFields(false)
    } else if (selectedGMAOMission?.gmao.is_matching_modele) {
      setRequiredFields([
        ...initialRequiredFields,
        'gmao',
        ...(selectedGMAOMission?.gmao.chantier_arrivee ? ['chantier_arrivee'] : []),
        ...(selectedGMAOMission?.gmao.voie_arrivee ? ['voie_arrivee'] : []),
        ...(selectedGMAOMission?.gmao.chantier_depart ? ['chantier_depart'] : []),
        ...(selectedGMAOMission?.gmao.voie_depart ? ['voie_depart'] : []),
        ...(selectedGMAOMission?.gmao.sens_depart ? ['sens_depart'] : []),
        ...(selectedGMAOMission?.gmao.via ? ['via'] : []),
      ])
      setCanEditRequiredFields(false)
    } else {
      setRequiredFields([
        ...initialRequiredFields,
        'gmao',
      ])
      setCanEditRequiredFields(true)
    }
  }, [formValues.modele, formValues.gmao, missionsOptions])
  // -------

  return [requiredFields, canEditRequiredFields] as const
}

export {
  useHandlers,
  useRequiredFields,
}
