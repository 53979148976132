import { useState } from 'react'
import terms from 'assets/terms'
import { AppRoles, ParamsTab } from 'types'
import { useAppSelector, usePageRoles } from 'utils'
import ParamsSideMenu from './ParamsSideMenu'
import './ParamsPage.scss'
import { MissionsForm, TechnicalCentersForm } from './forms'
import DefectsForm from './forms/defects/DefectsForm'
import ObservationsForm from './forms/observations/ObservationsForm'
import AdditionalMissionsForm from './forms/additionalMissions/AdditionalMissionsForm'

const TABS: ParamsTab[] = [
  { id: 'missions',
    title: terms.Pages.Params.tabs.missions,
    desc: terms.Pages.Params.description.missions,
    hint: terms.Pages.Params.hint.missions },
  { id: 'additionalMissions',
    title: terms.Pages.Params.tabs.additionalMissions,
    desc: terms.Pages.Params.description.additionalMissions,
    hint: terms.Pages.Params.hint.additionalMissions },
  { id: 'technicalCenters',
    title: terms.Pages.Params.tabs.technicalCenters,
    desc: terms.Pages.Params.description.technicalCenters,
    hint: terms.Pages.Params.hint.technicalCenters },
  { id: 'rdObservations',
    title: terms.Pages.Params.tabs.rdObservations,
    desc: terms.Pages.Params.description.rdObservations,
    hint: terms.Pages.Params.hint.rdObservations },
  { id: 'rdDefects',
    title: terms.Pages.Params.tabs.rdDefects,
    desc: terms.Pages.Params.description.rdDefects,
    hint: terms.Pages.Params.hint.rdDefects },
]

export default function ParamsPage() {
  usePageRoles([AppRoles.admin])
  const { isAdmin } = useAppSelector(state => state.app)
  const [activeTab, setActiveTab] = useState<ParamsTab>(TABS[0])

  const handleClickTab = (tab: ParamsTab) => () => {
    setActiveTab(tab)
  }

  const renderTabContent = (tab: string) => {
    if (!isAdmin) return null
    switch (tab) {
      case 'missions':
        return (<MissionsForm />)
      case 'additionalMissions':
        return (<AdditionalMissionsForm />)
      case 'technicalCenters':
        return (<TechnicalCentersForm />)
      case 'rdObservations':
        return (<ObservationsForm />)
      case 'rdDefects':
        return (<DefectsForm />)
      default:
        return null
    }
  }

  return (
    <div id="params-page" className="page">
      <ParamsSideMenu tabs={TABS} activeTab={activeTab} handleClickTab={handleClickTab} />
      <div className="params-form">
        <div className="form-header">
          <h2>{activeTab.title}</h2>
          <span>{activeTab.desc}</span>
          <span className="hint">{activeTab.hint}</span>
        </div>
        {renderTabContent(activeTab.id)}
      </div>

    </div>
  )
}
