import { GMAOModele } from './gmao'
import {
  ParamsAdditionalMission, ParamsConstruction,
  ParamsDefects, ParamsDirection, ParamsMission,
  ParamsObservations, ParamsTrack,
} from './params'

export enum MissionRDProgress {
  notStarted = 'notStarted',
  started = 'started',
  atEngine = 'atEngine',
  testsStarted = 'testsStarted',
  testsEnded = 'testsEnded',
}

export type AdditionnalMissionRD = {
  id: string
  done: boolean | null
  done_time: string | null
  modele?: ParamsAdditionalMission
  gmao?: GMAOModele
}

export type MissionRD = {
    id: string,
    numero: number,
    com: string,
    agent?: string,
    equipe?: string,
    heure_debut_theorique: string,
    heure_fin_theorique: string,
    observation_com?: string,
    observation_com_time?: string,
    commentaire_defauts_rd?: string,
    commentaire_observation_rd?: string,
    pancartage?: string,
    rame: string,
    um: boolean,
    um_details: string,
    statut: string,
    via?: string,
    from_voyageurs: boolean,
    prise_en_compte?: string,
    presence_sur_engin?: string,
    debut_npd?: string,
    fin_npd?: string,
    archivee: boolean,
    prioritaire: boolean,
    orientation_motrice?: {
      principale?: string;
      secondaire?: string;
    }
    modele?: ParamsMission,
    gmao?: GMAOModele,
    chantier_depart: ParamsConstruction | null,
    chantier_arrivee: ParamsConstruction | null,
    voie_depart: ParamsTrack | null,
    voie_arrivee: ParamsTrack | null,
    sens_depart: ParamsDirection | null,
    defauts_rd: ParamsDefects[],
    observations_rd: ParamsObservations[],
    missions_supplementaires: AdditionnalMissionRD[]
}

export type RdState = {
  missionsRD: MissionRD[];
  inProgressMissionsRD: MissionRD[];
  missionRD: MissionRD;
  loadingMissionRD: boolean;
}
