// import svg as React Component
import { ReactComponent as Warning } from 'assets/logos/warning.svg'
import terms from 'assets/terms'
import { Button, ButtonStyle, Loader } from 'components'
import './ModalBatchExport.scss'
import { MissionBatchAction } from 'types'
import { handleMissionBatchAction } from 'services'
import { useState } from 'react'
import { Check, ErrorOutline } from '@mui/icons-material'
import moment from 'moment'
import { get } from 'utils'

interface Props {
  technicalCenterId: string;
  selectedMissions: string[];
  handleClose: () => void;
}

export default function ModalBatchExport(
  { technicalCenterId, selectedMissions, handleClose }: Props,
) {
  const [status, setStatus] = useState<'pending' | 'success' | 'error' | null>(null)

  const handleValidate = handleMissionBatchAction(
    technicalCenterId,
    selectedMissions,
    resp => {
      setStatus('pending')
      const interval = setInterval(async () => {
        const r = await get(`/r2d2/status/${resp.id}/`)
        // SUCCESS
        if (r.status === 1) {
          clearInterval(interval)
          setStatus('success')
          const file: {type: 'Buffer', data: number[]} = r.result_file
          // download xlsx file
          // eslint-disable-next-line max-len
          const blob = new Blob([new Uint8Array(file.data)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `R2D2_export_${moment().format('YYYY_MM_DD_HH_mm_ss')}.xlsx`
          a.click()
          window.URL.revokeObjectURL(url)
        } else if (r.status === 2) {
          clearInterval(interval)
          setStatus('error')
          // ERROR
        }
      }, 1000)
    },
    MissionBatchAction.export,
  )
  return (
    <div className="modal-batch-export">
      {!status && (
        <>
          <div className="warning-icon">
            <Warning />
          </div>
          <h3>Êtes-vous sûr de vouloir exporter ces missions ?</h3>
          <div className="actions">
            <Button text={terms.Common.cancel} onClick={handleClose} style={ButtonStyle.light} />
            <Button text={terms.Common.continue} onClick={handleValidate} />
          </div>
        </>
      )}
      {status === 'pending' && (
        <div className="flex-center">
          <Loader message="Export des missions en cours" variant="large" />
        </div>
      )}
      {status === 'success' && (
        <>
          <div className="success-icon">
            <Check />
          </div>
          <h3>Export terminé</h3>
          <div className="actions">
            <Button text={terms.Common.close} onClick={handleClose} />
          </div>
        </>
      )}
      {status === 'error' && (
        <>
          <div className="error-icon">
            <ErrorOutline />
          </div>
          <h3>Une erreur inconnue est survenue</h3>
          <div className="actions">
            <Button text={terms.Common.close} onClick={handleClose} />
          </div>
        </>
      )}
    </div>
  )
}
