import { Autocomplete, TextField } from '@mui/material'
import { ReactElement, useState } from 'react'
import { CerbereUser, MissionAgent } from 'types'
import { useCerbereUsers } from 'utils'
import terms from 'assets/terms'
import HighlightMatch from './HighlightMatch'

interface UserSearchProps {
  placeholder: string
  label: string
  size: 'small' | 'medium'
  handleSelectUser: (user: CerbereUser, agent: MissionAgent) => void
  possibleAgents: MissionAgent[]
  className?: string
  required?: boolean
  disabled?: boolean
}
function UserSearch({
  placeholder,
  label,
  size,
  handleSelectUser,
  possibleAgents,
  required = false,
  disabled = false,
}: UserSearchProps): ReactElement {
  const { getUserName, getCerbereUser } = useCerbereUsers()
  const [users, setUsers] = useState<CerbereUser[]>(possibleAgents.map(agent => getCerbereUser(agent.user_id)))
  const [selectedUser, setSelectedUser] = useState<CerbereUser | null>(null)
  const [inputValue, setInputValue] = useState<string>('')

  const handleSearch = (newValue: string) => {
    setInputValue(newValue)
    setUsers(possibleAgents.map(agent => getCerbereUser(agent.user_id))
      .filter(u => getUserName(u.cp).toLowerCase().includes(newValue.toLowerCase())))
  }

  const handleChange = (_e: React.ChangeEvent, newValue: CerbereUser | null) => {
    setSelectedUser(newValue)
    handleSelectUser(newValue, possibleAgents.find(agent => agent.user_id === newValue?.cp))
  }

  const displayUserName = (user: CerbereUser) => getUserName(user.cp)

  return (
    <div className="user-search">
      <Autocomplete
        disablePortal
        disabled={disabled}
        loadingText={`${terms.Common.loading}...`}
        options={[
          ...(selectedUser ? [selectedUser] : []),
          ...users.filter(u => !!u && u?.cp !== selectedUser?.cp),
        ]}
        filterOptions={options => options}
        getOptionLabel={user => displayUserName(user)}
        isOptionEqualToValue={(option, value) => option.cp === value.cp}
        renderOption={(props, option) => (
          <li {...props} key={option.cp}>
            <HighlightMatch
              optionLabel={displayUserName(option)}
              inputValue={inputValue}
            />
          </li>
        )}
        value={selectedUser}
        noOptionsText={terms.Common.noResults}
        renderInput={params => (
          <TextField
            {...params}
            sx={{ '& .MuiFormLabel-asterisk': { color: '#DA4238' } }}
            placeholder={placeholder}
            fullWidth
            size={size}
            label={label}
            required={required}
            error={inputValue.length < 3 && inputValue.length !== 0}
          />
        )}
        onInputChange={(_, newInputValue) => handleSearch(newInputValue)}
        onChange={handleChange}
      />
    </div>
  )
}

export default UserSearch
